import React from 'react';
import UserRole from './UserRole';
import CasePolicies from './CasePolicies';
import Segments from './Segments';

const CaseManager = () => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent=".tab-pane" href="#collapseThree">
                        CaseManager
                    </a>
                </h4>
            </div>
            <div id="collapseThree" className="panel-collapse collapse">
                <div className="panel-body">
                    <div className="top-links mb-4">
                        <a className="lrn-lft" href="https://casemanager.ethicsglobal.com">https://casemanager.ethicsglobal.com  <span className="fa fa-external-link"></span></a>
                        <a className="lrn-rht" href="https://casemanager.ethicsglobal.com">Learn <span className="fa fa-external-link"></span></a>
                    </div>

                    <UserRole />
                    <Segments />
                    <CasePolicies />

                </div>
            </div>
        </div>
    );
}
 
export default CaseManager;