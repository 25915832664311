import React from 'react';

const UserRole = () => {
    return (
        <div className="card mb-4">
            <div className="card-header with-dp">
                <h2>USER ROLE</h2>
                <a href="#" className="video-icon">
                    <img src="images/video-icon.png" alt="" /> 
                </a>
            </div>
            <div className="card-body">
                <div className="">
                    <div className="btn-group btn-list" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-secondary blue-btn">Settled</button>
                        <button type="button" className="btn btn-secondary vio-btn">Individual support</button>
                        <button type="button" className="btn btn-secondary red-btn">Segment support</button>
                    </div>
                    <p className="m-0 para">
                        The user can only access the cases when a top-level user assigns them.<br />
                        <span>
                            <a href="">User dependencies <i className="fa fa-external-link"></i></a>
                            </span>
                    </p>
                </div>                                 
            </div>
        </div>
    );
}
 
export default UserRole;