import React from 'react';

const Start = (props) => {
    return (
        <div className="tab-inner-cont">
            <h2>Create an EthicsGlobal User</h2>
            <div style={{textAlign:'center'}}>
                <h3 >Let help us you creating an user credentials and policies for the entire EthicsGlobal Cloud</h3>
                <button type="button" className="btn btn-blue next-step" onClick={()=>{props.goNext()}}>Continue</button>
                <br /><br />
                <h5>OR</h5>
                <a href="#">Create multiple users at once</a>
            </div>
            

            
        </div>
    );
}
 
export default Start;