import React, {createContext, useState} from 'react'
export const GroupContext = createContext();

const GroupContextProvider = (props) => {

    const [groups, setGroup] = useState(
        [
            {
                'id':1,
                'name':'Advanced',
                'services':[
                    {
                        'id':'1',
                        'name':'Service Manager',
                        'description':'Manages EthicsGlobal services',
                        'active': true,
                        'mandatory': false,
                        'permissions':[
                            {
                                "name": "Service Plans",
                                "description": "View and manage service plans in addition to SLAs. The user will be able to request services upgrades and downgrades.",
                                "multiple": true,
                                "html_name": "service_manager[]",
                                "option": null,
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            },
                            {
                                "name": "Billing",
                                "description": "List billing and download invoices. Also the ability to dispute invoices and payment support.",
                                "multiple": true,
                                "html_name": "service_manager[]",
                                "option": null,
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            },
                            {
                                "name": "Support",
                                "description": "Request support to EthicsGlobal Customer Support. The user will be able to request changes to the service in your companies name.",
                                "multiple": true,
                                "html_name": "service_manager[]",
                                "option": null,
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            }
                        ]
                    },
                    {
                        'id':'2',
                        'name':'EthicsGlobal ID',
                        'description':'User credentials and policies manager',
                        'active': true,
                        'mandatory': true,
                        'permissions':[
                            {
                                "name": "Personal access credentials",
                                "description": "Just manage their own access credentials. This privilege is a default option but you can disable it in order for an administrator to have full control of the account.",
                                "multiple": true,
                                "html_name": "ethics_id_permission[]",
                                "option": null,
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            },
                            {
                                "name": "Administrator",
                                "description": "An administrator will be able to manage the whole access to EthicsGlobal Cloud for all users.",
                                "multiple": true,
                                "html_name": "ethics_id_permission[]",
                                "option": null,
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            }
                        ]
                    },
                    {
                        'id':'3',
                        'name':'Case Manager',
                        'description':'The advanced case manager application',
                        'active': true,
                        'mandatory': true,
                        'permissions':[
                            {
                                "name": "Standard configuration",
                                "description": "The user will be configured with the default options. Will have access to <b>all cases</b>, standard investigation workflow, common privileges and menus.",
                                "multiple": false,
                                "html_name": "case_manager[]",
                                "option": 'Use custom authorization policy',
                                "disabled": true,
                                "help": false,
                                "help_text":null,
                                "with_upgrade": false,
                            },
                            {
                                "name": "Authorization policy",
                                "description": "<ul><li>Role to define investigation dynamics</li><li>Notification methods configuration</li><li>User advanced properties</li><li>System usage permissions</li><li>Custom investigation workflow</li><li>Menu item access</li><li>Case segments to limit the user case visibility</li></ul>",
                                "multiple": false,
                                "html_name": "case_manager[]",
                                "option": 'Use standard configuration',
                                "disabled": true,
                                "help": true,
                                "help_text": "This configuration setup will continue after save",
                                "with_upgrade": true
                            }
                        ]
                    },
                    {
                        'id':'4',
                        'name':'EG-NOM 035',
                        'description':'Mexico psico social prevention standard',
                        'active': false,
                        'mandatory': false,
                        'permissions':[
                            {
                                "name": "Company manager",
                                "description": "Control workcenters, application sessions,results, assing evaluator and get reports.",
                                "multiple": false,
                                "html_name": "eg_nom_035[]",
                                "option": 'Set as company mananger',
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            },
                            {
                                "name": "Facilitator",
                                "description": "Apply questionnaires in-site to employees.",
                                "multiple": false,
                                "html_name": "eg_nom_035[]",
                                "option": 'Set as facilitator',
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            },
                            {
                                "name": "Evaluator",
                                "description": "Evaluae questionnaires results and upload reports to assigned workcenters and employees",
                                "multiple": false,
                                "html_name": "eg_nom_035[]",
                                "option": 'Set as evaluator',
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            }
                        ]
                    },
                    {
                        'id':'5',
                        'name':'EthicsTraining',
                        'description':'Train your skateholders',
                        'active': true,
                        'mandatory': false,
                        'permissions':[
                            {
                                "name": "Courses viewer",
                                "description": "Can view courses and get diplomas.",
                                "multiple": false,
                                "html_name": "ethics_training[]",
                                "option": 'Set as course viewer',
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            },
                            {
                                "name": "Courses viewer",
                                "description": "View usage statistics and courses",
                                "multiple": false,
                                "html_name": "ethics_training[]",
                                "option": 'Set as administrator',
                                "disabled": false,
                                "help": false,
                                "help_text": null
                            }
                        ]
                    },
                ]
            }
        ]
    );
    return (
        <GroupContext.Provider value={{groups, setGroup}}>
            {props.children}
        </GroupContext.Provider>
    );
}
 


export default GroupContextProvider;

