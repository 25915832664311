import React, { Fragment, useContext } from 'react';
import NavBar from '../components/NavBar/NavBar';
import SearchUser from '../components/SearchUser/SearchUser';
import UserList from '../components/UserList/UserList';
import { ViewContext } from '../contexts/store/ViewContext';
import { Scrollbars } from 'react-custom-scrollbars';
import { Switch, Route, Redirect } from "react-router-dom";
import routes from '../routes';

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/main") {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        })}
        <Redirect from="/" to="/users" />
    </Switch>
);

const Main = () => {

    const {toggled, setToggled} = useContext(ViewContext)

    return (
        <div id="wrapper" className={toggled === true ? "toggled" : ""}>
            <NavBar routes={routes} />
            <div className="content-bg-white mt-70">
                <div className="container-fluid p-4 full-pad">
                    <div className="p-4 leftside-bar" id="sidebar-wrapper">
                        <a  role="button" className="btn-default" id="menu-toggle-order-arr" alt="collapse menu" title="collapse menu" onClick={()=> setToggled(!toggled)}>
                            <img src="images/collapse-arrow.png" />
                        </a>
                        <SearchUser />

                        <div className="left-scroll col-md-12 p-0" id="content-4" style={{height:"100vh"}}>                               
                            <Scrollbars autoHide autoHeight autoHeightMin={100} autoHeightMax={"calc(85vh - 36px 35px - 75px)"}>
                                <UserList />
                            </Scrollbars>
                        </div>

                    </div>

                    <div id="page-content-wrapper" className="rightside-bar">
                        {switchRoutes}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Main;