import React from 'react';

const CaseMenu = () => {
    return (
        <div className="permission menu-li mt-4 col-md-12 p-0">
            <h3>Menus</h3>
            <ul className="nav nav-justified">
                <li className="nav-item">
                    <a className="nav-link active" href="#">Dashboard</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link nav-grey" href="#">Add grievance</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Grievances</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Reports</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link nav-grey" href="#">Log</a>
                </li>
            </ul>
        </div>
    );
}
 
export default CaseMenu;