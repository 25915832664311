import React from 'react';

const EthicsTraining = () => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent=".tab-pane" href="#collapseOne">
                        ServiceManager
                    </a>
                </h4>
            </div>
            <div id="collapseOne" className="panel-collapse collapse in show">
                <div className="panel-body">
                    <div className="top-links mb-4">
                        <a className="lrn-lft" href="https://casemanager.ethicsglobal.com">https://casemanager.ethicsglobal.com  <span className="fa fa-external-link"></span></a>
                        <a className="lrn-rht" href="https://casemanager.ethicsglobal.com">Learn <span className="fa fa-external-link"></span></a>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header with-dp">
                            <h2>PERMISSIONS</h2>
                            <a href="#" className="video-icon">
                                <img src="images/video-icon.png" alt="" /> 
                            </a>
                        </div>
                        <div className="card-body">
                            <div className="pol-list">                                               
                                <div className="permission mt-1">                                                   
                                    <table className="table table-bordered per-table service-tab">
                                        <thead>
                                            <tr>
                                            <th>Course Viewer</th>
                                            <th>Administrator</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>Can view courses and get diplomas.</td>
                                            <td>View usages statistics and courses.</td>
                                            </tr>                                              
                                            <tr>
                                            <td><input type="radio" name="role"/> Set as course viewer</td>
                                            <td><input type="radio" name="role"/> Set as administrator</td>
                                            
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>    
                            </div>                   
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header with-dp">
                            <h2>REGISTER CODE</h2>
                            <a href="#" className="video-icon">
                                <img src="images/video-icon.png" alt="" /> 
                            </a>
                        </div>
                        <div className="card-body">
                            <div style={{textAlign:'center'}}>
                                <h3><strong>GX7537</strong></h3>
                                Use this code to register all your users, <a href="#">learn more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default EthicsTraining;
