import CreateUser from "./views/CreateUser/CreateUser";
import Policies from "./views/Policies/Policies";
import UserMap from "./views/UserMap/UserMap";
import AddUser from "./views/AddUser/AddUser";
import Groups from "./views/Groups/Groups";
import PoliciesMatrix from "./views/PoliciesMatrix/PoliciesMatrix";
import ServiceManager from "./views/ServiceManager/ServiceManager";
import GroupMatrix from "./views/GroupMatrix/GroupMatrix";

const dashboardRoutes = [
    {
        path: "/users",
        name: "USERS",
        component: ServiceManager,
        layout: "/main",
        navBar: true
    },
    {
        path: "/policies",
        name: "CM POLICIES",
        component: PoliciesMatrix,
        layout: "/main",
        navBar: true
    },
    {
        path: "/new_policy",
        name: "CM POLICIES",
        component: Policies,
        layout: "/main"
    },
    {
        path: "/user_map",
        name: "USERS MAP",
        component: UserMap,
        layout: "/main",
        navBar: true
    },
    {
        path: "/groups",
        name: "GROUPS",
        component: GroupMatrix,
        layout: "/main",
        navBar: true
    },
    {
        path: "/create_user",
        name: "CREATE USER",
        component: CreateUser,
        layout: "/main",
        navBar: true
    },
];

export default dashboardRoutes;