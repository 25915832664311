import React, { Fragment, useContext, useState } from 'react';
import ItemUser from '../ItemUser.js/ItemUser';
import { UsersContext } from '../../contexts/store/UsersContext';
import { UserSelectedContext } from '../../contexts/store/UserSelectedContext';
import { withRouter } from "react-router";

const UserList = (props) => {

    const {setUserSelected} = useContext(UserSelectedContext);

    const {users} = useContext(UsersContext)
    const [selected, setSelected] = useState(null)
    

    return (
        <Fragment>
            {users.map((record,i)=>{
                return(
                    <div className="left-bar col-md-12 p-0" key={i}>
                        <h2>{record.group}</h2>
                        <ul>
                        {record.users.map((user,idx)=>{
                            return(
                                <li className={selected === user.id ? "active" : ""} key={idx} onClick={
                                    ()=> {
                                        setSelected(user.id);
                                        setUserSelected(user);
                                        props.history.push('/users')
                                    }
                                }>
                                    <ItemUser user={user} />
                                </li>
                            )
                        })}
                        </ul>
                    </div>
                )
            })}
        </Fragment>
    );
}
 
export default withRouter(UserList);