import React from 'react';
import CaseMenu from './CaseMenu';

const CasePolicies = () => {
    return (
        <div className="card mb-4">
            <div className="card-header with-dp">
                <h2>POLICY</h2>
                <button type="button" className="btn btn-secondary edit-btn">Edit policy</button>
            </div>
            <div className="card-body">
                <div className="pol-list">
                    <ul>
                        <li>Policy name: <span>Responsible</span></li>
                        <li>The user receives notifications from the service <span>everyday at 6 pm</span></li>
                        <li>User level <span>1</span></li>
                    </ul>  
                    <div className="permission mt-4">
                        <h3>Permissions</h3>
                        <table className="table table-bordered per-table">
                            <thead>
                                <tr>
                                <th width="40%">Permission</th>
                                <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Grievance approval</td>
                                <td>Approve the grievance that an advisor has captured, or self-approve a new grievance</td>
                                </tr>
                                <tr>
                                <td>Manage grievance actions</td>
                                <td>Change attention status, severity, corrective actions,resolutions.</td>
                                </tr>
                                <tr>
                                <td>Grievance assignation</td>
                                <td>Send messages to users of the system or people outside it delegating responsibilities.</td>
                                </tr>                                                         
                                <tr>
                                <td>Communication with whistleblowers</td>
                                <td>Sending messages and feedback with complainants.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="permission mt-4">
                        <h3>Investigation workflow</h3>
                        <table className="table table-bordered per-table">
                            <thead>
                                <tr>
                                <th width="40%">Step</th>
                                <th>Transitions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>New</td>
                                <td>Pending, In progress, Invalid</td>
                                </tr>
                                <tr>
                                <td>Pending</td>
                                <td>Pending, Invalid</td>
                                </tr>
                                <tr>
                                <td>In progress</td>
                                <td>Closed, Invalid</td>
                                </tr>                                                         
                                <tr>
                                <td>Closed</td>
                                <td>On track</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <CaseMenu />

                </div>                   
            </div>
        </div>
    );
}
 
export default CasePolicies;