import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { GlobalContext } from '../../contexts/store/GlobalContext';

const PoliciesMatrix = () => {

    const {config} = useContext(GlobalContext);

    return (
        <div className="card">
            <div className="card-header">
                <h2>POLICIES MATRIX</h2>
                <a href="#" className="video-icon">
                    <img src="images/video-icon.png" alt="" /> 
                </a>
            </div>
            <div className="card-body">
                <h3>The policies define the rules of usage and business process in CaseManager, you can create policies an later add users to them.</h3>
            
                <div className="btn-right m-t-10">
                    
                    <NavLink
                        to={"new_policy"}
                        className={'btn btn-grn'}
                    >
                        Create policy
                    </NavLink>
                </div>

                <div className="box-div">
                    <div className="table-box table-responsive">
                        <table className="table">
                            <thead className="thead-grey">
                                <tr>
                                <th></th>
                                {config.policies.map((p,idx)=>{
                                    return(
                                        <th key={idx}>{p}</th>
                                    )
                                })}
                                </tr>
                            </thead>
                            <tbody>
                                {config.user_matrix_policies.map((um,idx)=>{
                                    return(
                                        <tr key={idx}>
                                            <td>
                                                <p>{um.user}</p>
                                            </td>
                                            {um.policies.map((p,j)=>{
                                                return(
                                                    <td key={j}>
                                                        <div class="custom-control custom-radio radio-pm">
                                                            <input type="radio" id={"customRadio" + idx} name={"customRadio" + idx} class="custom-control-input" checked={p} />
                                                            <label class="custom-control-label" for={"customRadio" + idx}></label>
                                                        </div>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PoliciesMatrix;