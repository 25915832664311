import React, { Fragment, useState, useContext } from 'react';

import { Formik } from 'formik'
import * as Yup from 'yup'
import { NewUserContext } from '../../../contexts/store/NewUserContext';


const Profile = (props) => {

    const { dispatch } = useContext(NewUserContext)
    const [ validateForm, setValidateForm] = useState(true)

    const validationSchema = Yup.object().shape({
        first_name:Yup.string()
        .max(100,"Must be shorten than 100")
        .required("Must enter the name"),
        last_name: Yup.string()
        .max(100,"Must be shorten than 100")
        .required("Must enter the last name"),
        job_position: Yup.string()
        .max(100,"Must be shorten than 100")
        .required("Must enter the last name")
    })


    const disableValidation = (resetForm, event) =>{
        setValidateForm(!event.target.checked)
        resetForm()
    }

    return (
        <Fragment>
            <h2>How the user will be known in EthicsGlobal</h2>
            
            

            <Formik initialValues={{first_name:"",last_name:"",job_position:""}} 
                validationSchema={validateForm ? validationSchema : null}
                onSubmit={(values, {setSubmitting})=>{
                    if(validateForm){
                        values['provided_by_user'] = false;
                        dispatch({type:'SET_PROFILE',user:{values}});
                    }else{
                        let values = {
                            first_name: '',
                            last_name: '',
                            job_position: '',
                            provided_by_user: true
                        }
                        dispatch({type:'SET_PROFILE',user:{values}});
                    }
                    props.goNext();
                }}
            >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm}) => (
                    <Fragment>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" name="provided_by_user" className="custom-control-input" id="tabCheck1" onChange={(e) => disableValidation(resetForm, e)} />
                            <label className="custom-control-label" htmlFor="tabCheck1">Let the user provide this information</label>
                        </div>
                        <form id="profileForm" onSubmit={handleSubmit}>
                            <div className="tab-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" 
                                                name="first_name" 
                                                placeholder="Name"  
                                                onChange={handleChange} 
                                                onBlur={handleBlur}
                                                value={values.first_name} 
                                                className={touched.first_name && errors.first_name ? 'form-control is-invalid': 'form-control'}
                                                
                                            />
                                            {touched.first_name && errors.first_name ? (
                                                <div className="invalid-feedback">{errors.first_name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="lastname">Lastname</label>
                                            <input type="text" 
                                                name="last_name"
                                                placeholder="Lastname"  
                                                onChange={handleChange} 
                                                onBlur={handleBlur}
                                                value={values.last_name} 
                                                className={touched.last_name && errors.last_name ? 'form-control is-invalid': 'form-control'}
                                            />
                                            {touched.last_name && errors.last_name ? (
                                                <div className="invalid-feedback">{errors.last_name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Job position</label>
                                            <input type="text" 
                                                name="job_position" 
                                                placeholder=""  
                                                onChange={handleChange} 
                                                onBlur={handleBlur}
                                                value={values.job_position} 
                                                className={touched.job_position && errors.job_position ? 'form-control is-invalid': 'form-control'}
                                            />
                                            {touched.job_position && errors.job_position ? (
                                                <div className="invalid-feedback">{errors.job_position}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <ul className="float-right">
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-grey prev-step" onClick={()=> props.goBack()}>
                                        Previous step
                                    </button>
                                </li>
                                <li className="list-inline-item">
                                    <button type="submit" className="btn btn-blue next-step">Continue</button>
                                </li>
                            </ul>
                        </form>
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
}
 
export default Profile;