import React, { useState, Fragment } from 'react';
import Start from './Steps/Start';
import Profile from './Steps/Profile';
import Credentials from './Steps/Credentials';
import Group from './Steps/Group';
import Policy from './Steps/Policy';

const tabs = ['step1','step2','step3','step4','step5'];

const CreateUser = () => {

    const [step, setStep] = useState(0);

    const nextStep = () =>{
        console.log("Next")
        setStep(step+1)
    }

    const goBack = () =>{
        console.log("Next")
        setStep(step-1)
    }

    return (
        <div className="card">
            <div className="card-header">
                <h2>CREATE USER</h2>
                <a href="#" className="video-icon">
                    <img src="images/video-icon.png" alt="" /> 
                </a>
            </div>
            <div className="card-body">
                <section className="form-wizard">
                    <div className="wizard">
                        <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="nav-item" onClick={()=>setStep(0)}>
                                <a href="#step1"  className={step === 0 ? "nav-link active" : "nav-link disabled"} data-toggle="tab" aria-controls="step1" role="tab" title="Start"> Start </a>
                            </li>
                            <li role="presentation" className="nav-item" onClick={()=>setStep(step > 1 ? 1 :step)}>
                                <a href="#step2"  className={step === 1 ? "nav-link active" : "nav-link disabled"} data-toggle="tab" aria-controls="step2" role="tab" title="Profile"> Profile </a>
                            </li>
                            <li role="presentation" className="nav-item" onClick={()=>setStep(step > 2 ? 2 :step)}>
                                <a href="#step3"  className={step === 2 ? "nav-link active" : "nav-link disabled"} data-toggle="tab" aria-controls="step3" role="tab" title="Credentials"> Credentials </a>
                            </li>
                            <li role="presentation" className="nav-item" onClick={()=>setStep(step > 3 ? 3 :step)}>
                                <a href="#step4"  className={step === 3 ? "nav-link active" : "nav-link disabled"} data-toggle="tab" aria-controls="step4" role="tab" title="Group"> Group </a>
                            </li>
                            <li role="presentation" className="nav-item">
                                <a href="#complete"  className={step === 4 ? "nav-link active" : "nav-link disabled"} data-toggle="tab" aria-controls="complete" role="tab" title="Policy"> Policy </a>
                            </li>
                        </ul>

                        
                            <div className="tab-content">
                                <div className={step === 0 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step1">
                                    <div className="tab-inner-cont">
                                        <Start goNext={nextStep}/>
                                    </div>
                                                                            
                                </div>

                                <div className={step === 1 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step1">
                                    <div className="tab-inner-cont">
                                        <Profile goBack={goBack} goNext={nextStep} />
                                    </div>
                                                                            
                                </div>

                                <div className={step === 2 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step1">
                                    <div className="tab-inner-cont">
                                        <Credentials goBack={goBack} goNext={nextStep} />
                                    </div>
                                                                           
                                </div>

                                <div className={step === 3 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step1">
                                    <div className="tab-inner-cont">
                                        <Group goBack={goBack} goNext={nextStep} />
                                    </div>
                                                                           
                                </div>


                                <div className={step === 4 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step1">
                                    <div className="tab-inner-cont">
                                        <Policy goBack={goBack} />
                                    </div>
                                                                            
                                </div>
                                
                            </div>
                         
                    </div>
                </section>
            </div>
        </div>
    );
}
 
export default CreateUser;