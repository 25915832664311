import React, {createContext, useState} from 'react'
export const UsersContext = createContext();

const UsersContextProvider = (props) => {
    
    const [users, setUsers] = useState([
        {
            "group":"Corporate Administrators",
            "users": [
                {
                    "id": "1",
                    "name":"Fernando Fernandez",
                    "email": "fernando.fernandez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lblu-bg",
                },
                {
                    "id": "2",
                    "name":"Amelia Lopez",
                    "email": "amelia.lopez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "dblu-bg",
                },
                {
                    "id": "3",
                    "name":"Angelina Trujillo",
                    "email": "angelina.trujillo@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lgry-bg",
                },
                {
                    "id": "4",
                    "name":"Arcturus Perez y Perez",
                    "email": "arcturus.perez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lvio-bg",
                },
                {
                    "id": "5",
                    "name":"Ares Jimenez",
                    "email": "areas.jimenez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lblu-bg",
                }
            ]
        },
        
        {
            "group":"Responsible",
            "users": [
                {
                    "id": "6",
                    "name":"Fernando Fernandez",
                    "email": "fernando.fernandez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lblu-bg",
                },
                {
                    "id": "7",
                    "name":"Amelia Lopez",
                    "email": "amelia.lopez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "dblu-bg",
                },
                {
                    "id": "8",
                    "name":"Angelina Trujillo",
                    "email": "angelina.trujillo@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lgry-bg",
                },
                {
                    "id": "9",
                    "name":"Arcturus Perez y Perez",
                    "email": "arcturus.perez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lvio-bg",
                },
                {
                    "id": "10",
                    "name":"Ares Jimenez",
                    "email": "areas.jimenez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lblu-bg",
                }
            ]
        },
        {
            "group":"Directives",
            "users": [
                {
                    "id": "11",
                    "name":"Fernando Fernandez",
                    "email": "fernando.fernandez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lblu-bg",
                },
                {
                    "id": "12",
                    "name":"Amelia Lopez",
                    "email": "amelia.lopez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "dblu-bg",
                },
                {
                    "id": "13",
                    "name":"Angelina Trujillo",
                    "email": "angelina.trujillo@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lgry-bg",
                },
                {
                    "id": "14",
                    "name":"Arcturus Perez y Perez",
                    "email": "arcturus.perez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lvio-bg",
                },
                {
                    "id": "15",
                    "name":"Ares Jimenez",
                    "email": "areas.jimenez@gmail.com",
                    "policy":"Responsable Designado A",
                    "color": "lblu-bg",
                }
            ]
        },
    ]);
    
        
    return (
        <UsersContext.Provider value={{users, setUsers}}>
            {props.children}
        </UsersContext.Provider>
    );
}
 


export default UsersContextProvider;