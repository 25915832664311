import React, { Fragment, useState, useContext,useEffect } from 'react';

import { Formik } from 'formik'
import * as Yup from 'yup'
import { NewUserContext } from '../../../contexts/store/NewUserContext';

const Policy = (props) => {

    const { user, dispatch } = useContext(NewUserContext)

    

    const handleSubmit = () =>{
        console.log(user)
    }

    const setCellWidth = (permissions) =>{
        return (100/permissions.length + "%")
    }

    if(user.group!=null){

        return (
            <Fragment>
                <h2>Service usage authorization</h2>
                
                <div style={{textAlign:'center'}}>
                    <h3 >Define what de user can do on each EthicsGlobal service.</h3>
                </div>
                {user.group.services.map((s,idx)=>{
                    
                        return(
                            <Fragment key={idx}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <h4>{s.name}</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <table className="table table-bordered per-table service-tab">
                                            <thead>
                                                <tr>
                                                    {s.permissions.map((p,j)=>{
                                                        return(
                                                            <th key={j} width={setCellWidth(s.permissions)}>{p.name}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {s.permissions.map((p,j)=>{
                                                        return(
                                                            <td key={j} width={setCellWidth(s.permissions)}><div dangerouslySetInnerHTML={{__html:p.description}}></div></td>
                                                        )
                                                    })}
                                                </tr>
                                                <tr>
                                                    {s.permissions.map((p,j)=>{
                                                        if(p.multiple){
                                                            return(
                                                                <td key={j} width={setCellWidth(s.permissions)}>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" id={"listCheck"+j} name={p.html_name} />
                                                                        <label className="custom-control-label">Grant Privilege</label>
                                                                        {p.with_upgrade ? <a href="">Upgrade plan to use this feature</a> : null}
                                                                    </div>
                                                                </td>
                                                            )
                                                        }else{
                                                            if(p.help){
                                                                return(
                                                                    <td key={j} width={setCellWidth(s.permissions)}>
                                                                        <input type="radio" name={p.html_name} value="1" disabled={p.disabled} /> <label>{p.option}</label> <br />
                                                                        <small>{p.help_text}</small><br />
                                                                        {p.with_upgrade ? <a href="">Upgrade plan to use this feature</a> : null}
                                                                    </td>
                                                                )
                                                            }else{
                                                                return(
                                                                    <td key={j} width={setCellWidth(s.permissions)}>
                                                                        <input type="radio" name={p.html_name} value="1" disabled={p.disabled} /> <label>{p.option}</label> <br />
                                                                        {p.with_upgrade ? <a href="">Upgrade plan to use this feature</a> : null}
                                                                    </td>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    
                })}
                
                <ul className="float-right">
                    <li className="list-inline-item">
                        <button type="button" className="btn btn-grey prev-step" onClick={()=> props.goBack()}>
                            Previous step
                        </button>
                    </li>
                    <li className="list-inline-item">
                        <button type="button" onClick={handleSubmit} className="btn btn-blue next-step">Save user</button>
                    </li>
                </ul>
            </Fragment>
        );
    }else{
        return null;
    }
}
 
export default Policy;