import React, { createContext, useReducer } from 'react'
import { newUserReducer } from '../reducers/newUser';


export const NewUserContext = createContext();

const NewUserContextProvider = (props) =>{
    const [user, dispatch] = useReducer(newUserReducer, {})

    return(
        <NewUserContext.Provider value={{user, dispatch}}>
            {props.children}
        </NewUserContext.Provider>
    )
}

export default NewUserContextProvider