export const newUserReducer = (state, action) => {
    let newState = state
    switch(action.type){
        
        case 'SET_PROFILE':
            return action.user.values;
        case 'SET_ACCESS_CREDENTIALS':            
            newState.email = action.access.email
            newState.auth_factor = action.access.auth_factor
            return newState
        case 'ADD_USER_TO_GROUP':
            newState.group = action.group;
        case 'SET_PERMISSIONS':
            newState.permissions = action.access;
        default:
            return state
    }
}