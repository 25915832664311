import React, { useState } from 'react';
import { NavLink } from "react-router-dom";


const NavBar = (props) => {

    const [toggleMenu, setToggleMenu] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    
    return (
        <nav className="navbar navbar-expand-lg navbar-black">
            <a className="menu-dot" href="#;">
                <img src="images/menu-dot.png" alt="" />
            </a>
            <a className="navbar-brand" href="#;">
                <img src="images/id-logo.png" alt="" /> 
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation" onClick={()=>setToggleMenu(!toggleMenu)}>
                <span className="navbar-toggler-icon"> <i className="fa fa-bars"></i></span>
            </button>

            <div className={toggleMenu ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    {props.routes.map((prop, key) => {
                        if(prop.navBar){
                            return (
                                
                                <li key={key} className={prop.path === '/create_user' ? "nav-item mb-menu" : "nav-item"}>
                                    <NavLink
                                        to={prop.path}
                                        className={'nav-link'}
                                        activeClassName="active"
                                        key={key}
                                    >
                                        {prop.name}
                                    </NavLink>
                                </li>
                            )
                        }
                    })}
                    
                    
                </ul>  
            </div>

            <div className="user-rgt my-lg-0">
                <ul>
                    <li className="create">
                        <NavLink
                            to={'/create_user'}
                            className={'btn-blue'}
                        >
                            Create user
                        </NavLink>
                    </li>
                    <li className="help">
                        <a href="#;">
                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="dropdown-toggle" href="#;" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" onClick={()=> setShowUserMenu(!showUserMenu)}>
                            <img src="images/user-icon.png" alt="" />
                        </a>
                    <div className={showUserMenu ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"} aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="#;">User Profile</a>
                        <a className="dropdown-item" href="#;">Settings</a>
                        <a className="dropdown-item" href="#;">Logout</a>
                    </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
 
export default NavBar;