import React, { Fragment, useState, useContext } from 'react';

import { Formik } from 'formik'
import * as Yup from 'yup'
import { NewUserContext } from '../../../contexts/store/NewUserContext';

const Credentials = (props) => {

    const { dispatch } = useContext(NewUserContext)

    const validationSchema = Yup.object().shape({
        email:Yup.string()
        .email('Must enter valid email')
        .max(100,"Must be shorten than 100")
        .required("Must enter email")
    })

    return (
        <Fragment>
            <h2>Authentication Credentials</h2>
            
            <div style={{textAlign:'center'}}>
                <h3 >The user will receive an email to continue the authorization process and define their own password</h3>
            </div>

            <Formik initialValues={{email:"",auth_factor:"email_password"}} 
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting})=>{
                    dispatch({type:'SET_ACCESS_CREDENTIALS',access:values});
                    props.goNext();
                }}
            >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm}) => (
                    <Fragment>
                        <form id="credentialForm" onSubmit={handleSubmit}>
                            <div className="tab-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" 
                                                name="email" 
                                                placeholder="Email"  
                                                onChange={handleChange} 
                                                onBlur={handleBlur}
                                                value={values.email} 
                                                className={touched.email && errors.email ? 'form-control is-invalid': 'form-control'}
                                                
                                            />
                                            {touched.email && errors.email ? (
                                                <div className="invalid-feedback">{errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div style={{textAlign:'center'}}>
                                                <p>Multiple factor authentication?</p>
                                            </div>
                                            <input type="radio" name="auth_factor" onChange={handleChange} value="email_password" defaultChecked /> <label>Just use email and password for authentication</label><br />
                                            <input type="radio" name="auth_factor" onChange={handleChange} value="token" /> <label>Token generator</label> <br />
                                            <input type="radio" name="auth_factor" onChange={handleChange} value="email" /> <label>Email</label> <br />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="float-right">
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-grey prev-step" onClick={()=> props.goBack()}>
                                        Previous step
                                    </button>
                                </li>
                                <li className="list-inline-item">
                                    <button type="submit" className="btn btn-blue next-step">Continue</button>
                                </li>
                            </ul>
                        </form>
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
}
 
export default Credentials;