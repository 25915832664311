import React from 'react';

const Manager = () => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent=".tab-pane" href="#collapseOne">
                        ServiceManager
                    </a>
                </h4>
            </div>
            <div id="collapseOne" className="panel-collapse collapse in show">
                <div className="panel-body">
                    <div className="top-links mb-4">
                        <a className="lrn-lft" href="https://casemanager.ethicsglobal.com">https://casemanager.ethicsglobal.com  <span className="fa fa-external-link"></span></a>
                        <a className="lrn-rht" href="https://casemanager.ethicsglobal.com">Learn <span className="fa fa-external-link"></span></a>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header with-dp">
                            <h2>PERMISSIONS</h2>
                            <a href="#" className="video-icon">
                                <img src="images/video-icon.png" alt="" /> 
                            </a>
                        </div>
                        <div className="card-body">
                            <div className="pol-list">                                               
                                <div className="permission mt-1">                                                   
                                    <table className="table table-bordered per-table service-tab">
                                        <thead>
                                            <tr>
                                            <th>Service Plans</th>
                                            <th>Billing</th>
                                            <th>Support</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>View and manage service
                                                plans in addition to SLAs. The
                                                user will be able to request
                                                services upgrades and
                                                downgrades.</td>
                                            <td>List billing and download
                                                invoices. Also the ability to
                                                dispute invocies and payment
                                                support.</td>
                                            <td>Request assistance to
                                                EthicsGlobal Customer
                                                Support. The user will be able
                                                to request changes to the
                                                service in your companies
                                                name.</td>
                                            </tr>
                                                                                                
                                            <tr>
                                            <td><img src="images/tick-box.png" />Grant privilege</td>
                                            <td><img src="images/tick-box.png" />Grant privilege</td>
                                            <td><img src="images/tick-box.png" />Grant privilege</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>    
                            </div>                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Manager;