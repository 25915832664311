import React, {createContext, useState} from 'react'
export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {
    
    const [config, setConfig] = useState(
        {
            "permissions":[
                "Case management tab",
                "User case assigments",
                "Messaging with whistle-blowers",
                "Add investigation attachment files",
                "Add resolutions",
                "Receive cases events alerts",
                "Log access",
                "Internal messaging",
                "Dashboard messages",
                "Case lock and case dismiss",
                "Case partial edition",
                "Download case file (pdf)",
                "Investigation basic tools",
                "View case recipients",
                "Investigation flow",
                "Global filters",
                "View cases confidential data",
            ],
            "investigation_flow":[
                {
                    "flow":"Draft",
                    "transitions": ["Nuevo","Pendiente de","Seguimiento"]
                },
                {
                    "flow":"New",
                    "transitions": ["Nuevo","Pendiente de","Seguimiento"]
                },
                {
                    "flow":"In revision",
                    "transitions": ["Nuevo","Pendiente de","Seguimiento"]
                },
                {
                    "flow":"Follow-up",
                    "transitions": ["Nuevo","Pendiente de","Seguimiento"]
                }
            ],
            "policies":[
                "Policy A",
                "Policy B",
                "Policy C",
                "Policy D",
                "Policy E",
                "Policy F",
                "Policy G"
            ],
            "user_matrix_policies":[
                {
                    "user":"Amelia López",
                    "policies":[false,true,false,false,false,false,false],
                },
                {
                    "user":"Angelina Trujillo A.",
                    "policies":[true,false,false,false,false,false,false],
                },
                {
                    "user":"Arcturus Trujillo A.",
                    "policies":[false,false,false,false,true,false,false],
                },
                {
                    "user":"Ares Jimenez H.",
                    "policies":[false,false,true,false,false,false,false],
                },
                {
                    "user":"Fernando Fernandez",
                    "policies":[false,false,false,false,false,true,false],
                },
                {
                    "user":"Luis Fernando Carrillo M.",
                    "policies":[false,false,false,true,false,false,false],
                },
                {
                    "user":"Jorge Ortega M.",
                    "policies":[true,false,false,false,false,false,false],
                }
            ],
            "group_matrix":[
                "Corporate Administrator",
                "Zone A",
                "North Region",
                "Local Admins",
                "Southeast Region",
                "R.Admins",
                "Zone B"
            ],

        }
    );
    
        
    return (
        <GlobalContext.Provider value={{config, setConfig}}>
            {props.children}
        </GlobalContext.Provider>
    );
}
 


export default GlobalContextProvider;