import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { GlobalContext } from '../../contexts/store/GlobalContext';

const GroupMatrix = () => {

    const {config} = useContext(GlobalContext);

    return(
        <div className="row">
            <div className="col-sm-12 col-md-12">
                <div className="card">
                    <div className="card-header with-dp">
                        <h2>USER GROUPS</h2>
                        <a href="#" className="video-icon">
                            <img src="images/video-icon.png" alt="" /> 
                        </a>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" style={{textAlign:'center'}}>
                                <h3>Join user in teams grouping them, also to grant access to products click on the product icon.</h3>
                                <div className="btn-right m-t-10">
                                    <NavLink
                                        to={""}
                                        className={'btn btn-grn'}
                                    >
                                        Create group
                                    </NavLink>
                                </div>
                                <div className="box-div">
                                    <div className="table-box table-responsive">
                                        <table className="table">
                                            <thead className="thead-grey">
                                                <tr>
                                                <th></th>
                                                {config.group_matrix.map((p,idx)=>{
                                                    return(
                                                        <th key={idx}>
                                                            {p}
                                                        </th>
                                                    )
                                                })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {config.user_matrix_policies.map((um,idx)=>{
                                                    return(
                                                        <tr key={idx}>
                                                            <td>
                                                                <p>{um.user}</p>
                                                            </td>
                                                            {um.policies.map((p,j)=>{
                                                                return(
                                                                    <td key={j}>
                                                                        <div class="custom-control custom-radio radio-pm">
                                                                            <input type="radio" id={"customRadio" + idx} name={"customRadio" + idx} class="custom-control-input" checked={p} />
                                                                            <label class="custom-control-label" for={"customRadio" + idx}></label>
                                                                        </div>
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <ul className="float-right" style={{marginTop:'20px'}}>
                    <li className="list-inline-item">
                        <button type="button" className="btn btn-blue">
                            Save group configuration
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default GroupMatrix;