import React, {createContext, useState} from 'react'
export const UserSelectedContext = createContext();

const UserSelectedContextProvider = (props) => {
    const [userSelected, setUserSelected] = useState(null);

    return(
        <UserSelectedContext.Provider value={{userSelected,setUserSelected}}>
            {props.children}
        </UserSelectedContext.Provider>
    )
}

export default UserSelectedContextProvider;