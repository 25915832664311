import React, {createContext, useState} from 'react'
export const ViewContext = createContext();

const ViewContextProvider = (props) => {
    const [toggled, setToggled] = useState(false);

    return(
        <ViewContext.Provider value={{toggled,setToggled}}>
            {props.children}
        </ViewContext.Provider>
    )
}

export default ViewContextProvider;