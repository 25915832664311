import React, {createContext, useState} from 'react'
export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

    const [services, setService] = useState(
        [
            {
                'id':'1',
                'name':'Service Manager',
                'description':'Manages EthicsGlobal services',
                'mandatory': false,
                'permissions':[]
            },
            {
                'id':'2',
                'name':'ID',
                'description':'User credentials and policies manager',
                'mandatory': true,
                'permissions':[]
            },
            {
                'id':'3',
                'name':'Case Manager',
                'description':'The advanced case manager application',
                'mandatory': true,
                'permissions':[]
            },
            {
                'id':'4',
                'name':'EG-NOM 035',
                'description':'Mexico psico social prevention standard',
                'mandatory': false,
                'permissions':[]
            },
            {
                'id':'5',
                'name':'EthicsTraining',
                'description':'Train your skateholders',
                'mandatory': false,
                'permissions':[]
            },
        ]
    );
    return (
        <ServiceContext.Provider value={{services, setService}}>
            {props.children}
        </ServiceContext.Provider>
    );
}
 


export default ServiceContextProvider;

