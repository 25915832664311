import React, {useState} from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import { faChevronRight, faChevronDown, faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Segments = () => {

    const [data, setData] = useState([
        {
            value: 'employee',
            label: 'Employee',
            showCheckbox:false,
            children: [
                { 
                    value: 'usa', 
                    label: 'USA',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: 'femsa', 
                            label: 'FEMSA Logistics',
                            showCheckbox:false,
                            children:[
                                {
                                    value:'imbera',
                                    label:'Imbera',
                                    showCheckbox:false
                                },
                                {
                                    value:'imbera',
                                    label:'Imbera',
                                    showCheckbox:false
                                },
                                {
                                    value:'imbera',
                                    label:'Imbera',
                                    showCheckbox:false
                                }
                            ]
                        },
                    ]
                },
                {
                    value: 'femsa', 
                    label: 'FEMSA Logistics',
                    showCheckbox:false ,
                    children: [
                        {
                            value:'imbera',
                            label:'Imbera',
                            showCheckbox:false
                        },
                        {
                            value:'imbera',
                            label:'Imbera',
                            showCheckbox:false
                        },
                        {
                            value:'imbera',
                            label:'Imbera',
                            showCheckbox:false
                        }
                    ]
                }
            ],
        },
        {
            value: 'provider',
            label: 'Provider',
            showCheckbox:false,
            children: [
                { 
                    value: 'usa', 
                    label: 'USA',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: 'femsa', 
                            label: 'FEMSA Logistics',
                            showCheckbox:false,
                            children:[
                                {
                                    value:'imbera',
                                    label:'Imbera',
                                    showCheckbox:false
                                },
                                {
                                    value:'imbera',
                                    label:'Imbera',
                                    showCheckbox:false
                                },
                                {
                                    value:'imbera',
                                    label:'Imbera',
                                    showCheckbox:false
                                }
                            ]
                        },
                    ]
                },
                {
                    value: 'femsa', 
                    label: 'FEMSA Logistics',
                    showCheckbox:false ,
                    children: [
                        {
                            value:'imbera',
                            label:'Imbera',
                            showCheckbox:false
                        },
                        {
                            value:'imbera',
                            label:'Imbera',
                            showCheckbox:false
                        },
                        {
                            value:'imbera',
                            label:'Imbera',
                            showCheckbox:false
                        }
                    ]
                }
            ],
        },
        {
            value:'india',
            label:'India',
            showCheckbox:false,
            children: [
                {
                    value:'cocacola',
                    label:'Coca Cola Femsa',
                    showCheckbox:false
                }
            ]
        },
        {
            value:'north_carolina',
            label:'North Carolina',
            showCheckbox:false,
            children: [
                {
                    value:'cocacola',
                    label:'Coca Cola Femsa',
                    showCheckbox:false
                }
            ]
        }
    ]);
    const [expanded, setExpanded] = useState([])   

    return (
        <div className="card mb-4">
            <div className="card-header with-dp">
                <h2>AUTHORIZED SEGMENTS</h2>
                <a href="#" className="video-icon">
                    <img src="images/video-icon.png" alt="" /> 
                </a>
            </div>
            <div className="card-body">
                <div className="col-md-12 p-0">
                    <div className="col-md-6 p-0 node-tree">
                        
                        <button type="button" className="btn btn-secondary edit-btn add-seg">Add segment</button>
                        <div>
                        
                            <CheckboxTree
                                nodes={data}
                                expanded={expanded}
                                onExpand={expanded => {setExpanded(expanded)}}
                                iconsClass="fa5"
                                icons={{
                                    expandClose: <FontAwesomeIcon  icon={faChevronRight} />,
                                    expandOpen: <FontAwesomeIcon  icon={faChevronDown} />,
                                    expandAll: <FontAwesomeIcon color="#72bb53" icon={faPlusCircle} />,
                                    collapseAll: <FontAwesomeIcon icon={faMinusCircle} />,
                                    parentClose: <FontAwesomeIcon color="#72bb53" icon={faPlusCircle} />,
                                    parentOpen: <FontAwesomeIcon color="#72bb53" icon={faPlusCircle} />,
                                    leaf: null
                                }}
                            />
                        </div>
                        
                        <button type="button" className="btn btn-secondary apply-btn">Apply segment configuration</button>
                    </div>

                    <div className="col-md-5 user-case">
                        <p>This user has access to</p>
                        <h1>735 cases</h1>
                        <ul>
                            <li><a href="">View cases  <span className="fa fa-external-link"></span></a> </li>
                            <li><a href="">Documentation  <span className="fa fa-external-link"></span></a></li>
                        </ul>
                    </div>
                </div>                                 
            </div>
        </div>
    );
}
 
export default Segments;
