import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter } from 'react-router-dom';
import Main from './layouts/Main';
import UsersContextProvider from './contexts/store/UsersContext';
import ViewContextProvider from './contexts/store/ViewContext';
import GlobalContextProvider from './contexts/store/GlobalContext';
import UserSelectedContextProvider from './contexts/store/UserSelectedContext';
import NewUserContextProvider from './contexts/store/NewUserContext';
import GroupContextProvider from './contexts/store/GroupContext';
import ServiceContextProvider from './contexts/store/ServicesContext';

function App() {
  return (
      <BrowserRouter>
        <ViewContextProvider>
          <GlobalContextProvider>
            <UsersContextProvider>
              <UserSelectedContextProvider>
                <NewUserContextProvider>
                  <GroupContextProvider>
                    <ServiceContextProvider>
                      <Main />
                    </ServiceContextProvider>
                  </GroupContextProvider>
                </NewUserContextProvider>
              </UserSelectedContextProvider>
            </UsersContextProvider>
          </GlobalContextProvider>
        </ViewContextProvider>
      </BrowserRouter>
    
  );
}

export default App;
