import React, { useContext } from 'react';
import { GlobalContext } from '../../contexts/store/GlobalContext';

const Policies = () => {

    const {config} = useContext(GlobalContext);

    return (
        <div className="card">
            <div className="card-header">
                <h2>CREATE CASE MANAGER POLICY</h2>
                <a href="#" className="video-icon">
                    <img src="images/video-icon.png" alt="" /> 
                </a>
            </div>
            <div className="card-body">
                <h3>The policies define the rules of usage and business process in CaseManager, you can create policies an later add users to them.</h3>
                <div className="box-div">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="card-inbox">
                                <h2>Policy name</h2>
                                <form>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="compliance" placeholder="Compliance Officer" />
                                    </div>
                                </form>
                            </div>
                        </div>
                
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="card-inbox">
                                <h2>CaseManager menu</h2>
                                <ul className="nav nav-justified">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#">Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav-grey" href="#">Add grievance</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Grievances</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Reports</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav-grey" href="#">Log</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box-div">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="card-inbox">
                                <h2>Properties</h2>
                                <form>
                                <div className="form-group">
                                    <label htmlFor="user-level">User Level</label>
                                    <select className="form-control width120" id="user-level">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>

                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="defaultCheck1" />
                                        <label className="custom-control-label" htmlFor="defaultCheck1">Case segmentation</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="defaultCheck2" />
                                        <label className="custom-control-label" htmlFor="defaultCheck2">Active</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <textarea className="form-control" id="description" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="notification">Notification type</label>
                                    <select className="form-control width210" id="notification">
                                        <option>Per event</option>
                                        <option>Per event</option>
                                        <option>Per event</option>
                                        <option>Per event</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="defaultCheck3" />
                                        <label className="custom-control-label" htmlFor="defaultCheck3">Advanced notification rules</label>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>



                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="card-inbox">
                                <h2>Permissions</h2>
                                <form>
                                    <ul className="per-list">
                                        {config.permissions.map((p,idx)=>{
                                            return(
                                                <li key={idx}>
                                                    <span className="help-ico">
                                                        <i className="fa fa-question-circle"></i>
                                                    </span>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id={"listCheck"+idx} />
                                                        <label className="custom-control-label" htmlFor={"listCheck"+idx}>{p}</label>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </form>
                                <a href="#" className="req-link">Request advanced permission</a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="card-inbox">
                                <h2>Investigation workflow</h2>
                                {config.investigation_flow.map((inv,i)=>{
                                    return(
                                        <div className="workflow-box"  key={i}>
                                            <form>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id={"workflowCheck" + i} />
                                                    <label className="custom-control-label" htmlFor={"workflowCheck" + i}>{inv.flow}</label>
                                                </div>
                                                <div className="form-group">
                                                    <select className="selectpicker form-control">
                                                        <option>Añadir status de transición</option>
                                                        <option>Añadir status de transición</option>
                                                        <option>Añadir status de transición</option>
                                                        <option>Añadir status de transición</option>
                                                    </select>
                                                </div>
                                                <h3>Puede hacer transición</h3>
                                                <ul className="workflow-tags">
                                                    {inv.transitions.map((t,j)=>{
                                                        return(
                                                            <li key={j}>
                                                                {t}
                                                                <a href="#" className="close-link">
                                                                <i className="fa fa-close"></i>
                                                                </a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </form>
                                        </div>
                                    )
                                })}            
                            </div>
                        </div>
                    </div>    
                </div>
                <div className="btn-right mt-0">
                    <a href="#" className="btn btn-blue">Save policy</a>
                </div>
            </div>
        </div>
    );
}
 
export default Policies;