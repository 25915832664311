import React, { Fragment, useState, useContext, useEffect } from 'react';
import { NewUserContext } from '../../../contexts/store/NewUserContext';
import { GroupContext } from '../../../contexts/store/GroupContext';
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceContext } from '../../../contexts/store/ServicesContext';

const Group = (props) => {

    const { dispatch } = useContext(NewUserContext)
    const {groups} = useContext(GroupContext);
    const {services} = useContext(ServiceContext);
    const [option, setOption] = useState('existing')
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedServices, setSelectedService] = useState([])
    const [groupName, setGroupName] = useState('');

    const handleSelection = (e) =>{
        let radioOpt = e.target.value;
        setOption(radioOpt)
        if(radioOpt === 'new'){
            setSelectedGroup(null)
            let mandatory = [];
            for(var i=0;i<services.length;i++){
                if(services[i].mandatory){
                    mandatory.push(services[i].id);
                }
            }
            setSelectedService(mandatory)
        }else{
            setGroupName('')
        }
    }

    const handleChange = (e) =>{
        let selection = JSON.parse(e.target.value);
        if(selection!=null){
            setSelectedGroup(selection)
        }
        
    }

    const handleChecked = (e) =>{
        let checkbox = e.target;
        if(checkbox.checked){
            console.log("CHECKED")
            if(!selectedServices.includes(checkbox.value)){
                console.log("No exists")
                let tmp = selectedServices;
                tmp.push(checkbox.value);
                console.log(tmp)
                setSelectedService(tmp)
            }
        }else{
            console.log("NOT CHECKED")
            if(selectedServices.includes(checkbox.value)){
                console.log("Exists")
                let tmp = selectedServices;
                var index = tmp.indexOf(checkbox.value);
                if (index !== -1) {
                    tmp.splice(index, 1);
                    setSelectedService(tmp);
                }
            }
        }
    }

    const handleSubmit = () =>{
        if(option === 'existing'){
            console.log(selectedGroup)
            dispatch({type:'ADD_USER_TO_GROUP',group:selectedGroup});
            props.goNext();
        }else{
            let groupData = {};
            if(groupName!=''){
                groupData['name'] = groupName;
                groupData['new'] = true;
                let serviceData = [];
                console.log(selectedServices)
                for(var i=0;i<services.length;i++){
                    if(selectedServices.includes(services[i].id)){
                        serviceData.push(services[i]);
                    }
                }
                groupData['services'] = serviceData;
                console.log(groupData)
                dispatch({type:'ADD_USER_TO_GROUP',group:groupData});
                props.goNext();
            }else{
                alert('Group name is required!')
            }
            
        }
    }

    return (
        <Fragment>
            <h2>Add the user to a group</h2>
            
            <div style={{textAlign:'center'}}>
                <h3 >Group users to allow access to EthicsGlobal products</h3>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign:'center'}}>
                            <input type="radio" name="group" onChange={handleSelection} value="existing" defaultChecked /> <label><h4>Add user to an existing group</h4></label><br />
                        
                            <select name="" className="form-control" onChange={handleChange} value={JSON.stringify(selectedGroup)} disabled={option==='new'}>
                                <option value=""></option>
                                {groups.map((g, idx)=>{
                                    return(
                                        <option key={idx} value={JSON.stringify(g)}>
                                            {g.name}
                                        </option>
                                    )
                                })}                            
                            </select>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'10px'}}>
                        <div className="col-sm-12 col-md-6">
                            <a href="#" style={{textDecoration:'underline'}}>Users in this group</a> 
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <a href="#" style={{textDecoration:'underline'}}>Group matrix</a>
                        </div>
                        
                        
                    </div>
                    
                </div>
                <div className="col-sm-12 col-md-6" style={{textAlign:'center'}}>
                    <input type="radio" name="group" onChange={handleSelection} value="new" /> <label><h4>Create a new group for this user</h4></label><br />
                    <input type="text" name="group_name" value={groupName} onChange={(e)=>{setGroupName(e.target.value)}} className="form-control" placeholder="Group Name" />
                </div>
            </div>

            <div className="row" style={{marginTop:'10px'}}>
                <div className="col-sm-12 col-md-12">
                    {
                        option === 'existing' ?
                        (
                            <table className="table table-bordered per-table service-tab">
                                <tbody>
                                    {
                                        selectedGroup!=null && 
                                        selectedGroup.services.map((s,idx)=>{
                                            return(
                                                <tr key={idx}>
                                                    <td>
                                                        {
                                                            s.active ? 
                                                                <FontAwesomeIcon color="#72bb53" icon={faCheck} />
                                                            :
                                                                <FontAwesomeIcon color="#CECECE" icon={faTimesCircle} />
                                                        }
                                                    </td>
                                                    <td>{s.name}</td>
                                                    <td>{s.description}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        )
                        :
                        (
                            <table className="table table-bordered per-table service-tab">
                                <tbody>
                                    {
                                        services.map((s,idx)=>{
                                            return(
                                                <tr key={idx}>
                                                    <td>
                                                        {
                                                            s.mandatory ? 
                                                                <FontAwesomeIcon color="#72bb53" icon={faCheck} />
                                                            :
                                                                <input type="checkbox" value={s.id} onChange={handleChecked} />
                                                        }
                                                    </td>
                                                    <td>{s.name}</td>
                                                    <td>{s.description}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        )
                    }
                </div>
            </div>

            <ul className="float-right">
                <li className="list-inline-item">
                    <button type="button" className="btn btn-grey prev-step" onClick={()=> props.goBack()}>
                        Previous step
                    </button>
                </li>
                <li className="list-inline-item">
                    <button type="button" className="btn btn-blue next-step"  onClick={handleSubmit}>Continue</button>
                </li>
            </ul>
        </Fragment>
    );
}
 
export default Group;