import React, {useState} from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { faChevronRight, faChevronDown, faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserMap = () => {

    const [expandedLevel, setExpandedLevel] = useState([])   
    const [dataLevel, setDataLevel] = useState([
        {
            value: 'level1',
            label: 'Level 1',
            showCheckbox:false,
            children: [
                { 
                    value: '1', 
                    label: 'Fernando Fernandez',
                    showCheckbox:false ,
                    children: []
                },
                { 
                    value: '2', 
                    label: 'Amelia Lopez',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: '21', 
                            label: 'Rosa Rivera G',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        },
                        { 
                            value: '22', 
                            label: 'Maria Sosa',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        }
                    ]
                },
            ]
        },
        {
            value: 'level2',
            label: 'Level 2',
            showCheckbox:false,
            children: [
                { 
                    value: '1', 
                    label: 'Fernando Fernandez',
                    showCheckbox:false ,
                    children: []
                },
                { 
                    value: '2', 
                    label: 'Amelia Lopez',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: '21', 
                            label: 'Rosa Rivera G',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        },
                        { 
                            value: '22', 
                            label: 'Maria Sosa',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        }
                    ]
                },
            ]
        },
        {
            value: 'level3',
            label: 'Level 3',
            showCheckbox:false,
            children: [
                { 
                    value: '1', 
                    label: 'Fernando Fernandez',
                    showCheckbox:false ,
                    children: []
                },
                { 
                    value: '2', 
                    label: 'Amelia Lopez',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: '21', 
                            label: 'Rosa Rivera G',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        },
                        { 
                            value: '22', 
                            label: 'Maria Sosa',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        }
                    ]
                },
            ]
        },
        {
            value: 'level4',
            label: 'Level 4',
            showCheckbox:false,
            children: [
                { 
                    value: '1', 
                    label: 'Fernando Fernandez',
                    showCheckbox:false ,
                    children: []
                },
                { 
                    value: '2', 
                    label: 'Amelia Lopez',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: '21', 
                            label: 'Rosa Rivera G',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        },
                        { 
                            value: '22', 
                            label: 'Maria Sosa',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        }
                    ]
                },
            ]
        }
    ])

    const [expandedSegment, setExpandedSegment] = useState([])   
    const [dataSegment, setDataSegment] = useState([
        {
            value: 'segment1',
            label: 'Employee',
            showCheckbox:false,
            children: [
                { 
                    value: '1', 
                    label: 'Fernando Fernandez',
                    showCheckbox:false ,
                    children: []
                },
                { 
                    value: '2', 
                    label: 'Amelia Lopez',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: '21', 
                            label: 'Rosa Rivera G',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        },
                        { 
                            value: '22', 
                            label: 'Maria Sosa',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        }
                    ]
                },
            ]
        },
        {
            value: 'segment2',
            label: 'USA',
            showCheckbox:false,
            children: [
                { 
                    value: '1', 
                    label: 'Fernando Fernandez',
                    showCheckbox:false ,
                    children: []
                },
                { 
                    value: '2', 
                    label: 'Amelia Lopez',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: '21', 
                            label: 'Rosa Rivera G',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        },
                        { 
                            value: '22', 
                            label: 'Maria Sosa',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        }
                    ]
                },
            ]
        },
        {
            value: 'segment3',
            label: 'FEMSA Logistics',
            showCheckbox:false,
            children: [
                { 
                    value: '1', 
                    label: 'Fernando Fernandez',
                    showCheckbox:false ,
                    children: []
                },
                { 
                    value: '2', 
                    label: 'Amelia Lopez',
                    showCheckbox:false ,
                    children: [
                        { 
                            value: '21', 
                            label: 'Rosa Rivera G',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        },
                        { 
                            value: '22', 
                            label: 'Maria Sosa',
                            showCheckbox:false ,
                            children: [
                                
                            ]
                        }
                    ]
                },
            ]
        },
        {
            value: 'segment4',
            label: 'Imbera',
            showCheckbox:false,
            children: [
            ]
        },
        {
            value: 'segment5',
            label: 'Provider',
            showCheckbox:false,
            children: [
            ]
        },
        {
            value: 'segment6',
            label: 'India',
            showCheckbox:false,
            children: [
            ]
        },
        {
            value: 'segment7',
            label: 'Coca Cola FEMSA',
            showCheckbox:false,
            children: [
            ]
        },
        {
            value: 'segment8',
            label: 'North Caroline',
            showCheckbox:false,
            children: [
            ]
        },
    ])

    return (
        <div>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="card">
                        <div className="card-header with-dp">
                            <h2>MAP BY LEVEL</h2>
                        </div>
                        <div className="card-body"  style={{minHeight:'500px'}}>
                            <div className="col-md-12 p-0">
                                <CheckboxTree
                                    nodes={dataLevel}
                                    expanded={expandedLevel}
                                    onExpand={expanded => {setExpandedLevel(expanded)}}
                                    iconsClass="fa5"
                                    icons={{
                                        expandClose: <FontAwesomeIcon color="#3E8ABC" icon={faChevronRight} />,
                                        expandOpen: <FontAwesomeIcon  color="#3E8ABC" icon={faChevronDown} />,
                                        expandAll: null,
                                        collapseAll: null,
                                        parentClose: null,
                                        parentOpen: null,
                                        leaf: null
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="card">
                        <div className="card-header with-dp">
                            <h2>MAP BY SEGMENT AND DEPENDENCIES</h2>
                        </div>
                        <div className="card-body" style={{minHeight:'500px'}}>
                            <div className="col-md-12 p-0">
                                <CheckboxTree
                                    nodes={dataSegment}
                                    expanded={expandedSegment}
                                    onExpand={expanded => {setExpandedSegment(expanded)}}
                                    iconsClass="fa5"
                                    icons={{
                                        expandClose: <FontAwesomeIcon color="#3E8ABC" icon={faChevronRight} />,
                                        expandOpen: <FontAwesomeIcon  color="#3E8ABC" icon={faChevronDown} />,
                                        expandAll: null,
                                        collapseAll: null,
                                        parentClose: null,
                                        parentOpen: null,
                                        leaf: null
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default UserMap;