import React, { Fragment } from 'react';

const ItemUser = ({user}) => {
    return (
        <Fragment>
            <span className={user.color}>ff</span>
            <div className="admin-txt">
                <h3>{user.name}</h3>
                <p>Policy: <strong>{user.policy}</strong></p>
            </div>
        </Fragment>
    );
}
 
export default ItemUser;