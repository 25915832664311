import React, { Fragment, useContext, useState } from 'react';
import { UserSelectedContext } from '../../contexts/store/UserSelectedContext';
import Manager from './Manager';
import CaseManager from './CaseManager/CaseManager';
import ManagerID from './ManagerID';
import EgNom from './EgNom';
import EthicsTraining from './EthicsTraining';

const ServiceManager = () => {

    const {userSelected} = useContext(UserSelectedContext);
    const [openManage, setOpenManage] = useState(false);
    const [tab, setTab] = useState(0);

    if(userSelected!=null){
        return (
            <Fragment>
                <h2>{userSelected.name}</h2>
                <div className="card">
                    <div className="card-header with-dp">
                        <h2>AUTHENTICATION AND PROFILE</h2>
                        <div className="btn-group">
                            <a className="btn btn-primary dropdown-toggle" data-toggle="dropdown" onClick={()=> setOpenManage(!openManage)}>Manage <span className="caret"></span></a>
                            <ul className={openManage ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"} id="menu">
                                <li className="dropdown-item"><a href="#">Manage1</a></li>
                                <li className="dropdown-item"><a href="#">Manage2</a></li>
                                <li className="dropdown-item"><a href="#">Manage3</a></li>
                                <li className="dropdown-item"><a href="#">Manage4</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-md-12 p-0">
                            <div className="col-md-6 info-list pl-0 pr-0">
                                <h3>Information</h3>
                                <ul>
                                    <li>Email: <span><a href="#">{userSelected.email}</a></span></li>
                                    <li>Job title: <span>Compliance Office</span></li>
                                    <li>Group: <span className="text-blue">Corporate Administrator</span></li>
                                    <li>User type: <span>Standard</span></li>
                                    <li>User MFA: <span>No</span></li>
                                </ul>
                            </div>  

                            <div className="col-md-6 info-list pl-0 pr-0">
                                <h3>System usage</h3>
                                <ul>
                                    <li>Created at <span>january 4th,2019.</span></li>
                                    <li>Password last update on <span>june 12th, 2019</span></li>
                                    <li>Last access on <span>february 3rd, 2020.</span></li>
                                    <li>Last password reset at <span>february 6th,2019.</span></li>
                                    <li>17 logins <span>to EthicsGlobal.</span></li>
                                </ul>
                            </div>  
                        </div>                                 
                    </div>
                </div>

                <div className="tab-profile mt-4">
                    <div className="col-md-12 p-0">
                        <ul className="nav nav-tabs">
                            <li><a href="#tab1" data-toggle="tab" className={tab === 0 ? "active" : ""} onClick={()=> setTab(0)}>ServiceManager</a></li>  
                            <li><a href="#tab2" data-toggle="tab" className={tab === 1 ? "active" : ""} onClick={()=> setTab(1)}>EthicsGlobal ID</a></li>
                            <li><a href="#tab3" data-toggle="tab" className={tab === 2 ? "active" : ""} onClick={()=> setTab(2)}>CaseManager</a></li>
                            <li><a href="#tab4" data-toggle="tab" className={tab === 3 ? "active" : ""} onClick={()=> setTab(3)}>EGNOM035</a></li>
                            <li><a href="#tab5" data-toggle="tab" className={tab === 4 ? "active" : ""} onClick={()=> setTab(4)}>EthicsTraining</a></li>
                        </ul>

                        <div className="tab-content">
                            <div className={tab === 0 ? "tab-pane active" : "tab-pane"} id="tab1">
                                <Manager />
                            </div>
                            <div className={tab === 1 ? "tab-pane active" : "tab-pane"} id="tab2">
                                <ManagerID />
                            </div>
                            <div className={tab === 2 ? "tab-pane active" : "tab-pane"} id="tab3">
                                <CaseManager />
                            </div>
                            <div className={tab === 3 ? "tab-pane active" : "tab-pane"} id="tab4">
                                <EgNom />
                            </div>
                            <div className={tab === 4? "tab-pane active" : "tab-pane"} id="tab5">
                                <EthicsTraining />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }else{
        return null;
    }
}
 
export default ServiceManager;